import React from 'react';
import styled from 'styled-components';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import { NavOnlyTemplate } from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import Container from '@beelineloans/cx-library/src/components/layout/ContainerV2';
import { H2, H3, Paragraph } from '@beelineloans/cx-library/src/components/typography';
import { ButtonText } from '../../../../shared/GatsbyButton';

const Title = styled(H3)`
  padding-top: 40px;
`;

const Table = styled.table`
  padding: 40px;
  width: 100%;
  border-collapse: collapse;

  tr {
    border-bottom: 1px solid ${(props) => props.theme.colours.border.default};
    td,
    th {
      padding: 5px 0;
    }
  }
`;

const TD = styled.td`
  background: ${(props) => props.theme.colours.background.navy};
  text-align: center;
  color: ${(props) => props.theme.colours.text.light};
`;

const TH = styled.th`
  text-align: center;
`;

const LockBeforeYouBuy = () => (
  <NavOnlyTemplate
    headerProps={{
      hideButton: true
    }}
  >
    <SEO path={CONST.LINKS.MAIN.LEGAL.TERMS} title="Lock Before You Buy" description="Beeline Lock Before you Buy." />
    <Container>
      <H2>Beeline Lock Before you Buy.</H2>
      <Paragraph>
        Beeline’s ‘Lock before you buy’ lets you lock in your interest rate before you lock in the property you’ll buy — so you won’t have to worry about your rates, payments and
        affordability shifting around during your search and potentially spoiling the fun later.
      </Paragraph>
      <Paragraph>
        If you want that extra peace of mind that comes from having your rate firmly in place, we can get started while you wait for the perfect place to come on the market.
      </Paragraph>
      <Paragraph>
        Once Beeline locks your interest rate for you, we’ll begin reviewing your financial profile for assets, income and liabilities (debts). This gives us a head start on fully
        approving your loan — and it’s stuff we have to do anyway.
      </Paragraph>
      <Title>How to lock before you buy</Title>
      <Paragraph>If you’re feeling it, then reach out to your Loan Guide to get the rate locked and your financial profile reviewed so you know exactly where you stand.</Paragraph>
      <Title>T&amp;Cs</Title>
      <ul>
        <li>Beeline Lock Before You Buy only applies to a property that will be your primary residence. </li>
        <li>
          The primary residence can only be a single unit (i.e. single family home, condo, etc.) and cannot be a multi-unit property (even if you plan to occupy one of the units as
          your primary residence).
        </li>
        <li>This isn’t a commitment to lend just yet. You’ll still need to go through our review process.</li>
        <li>Your lock is initially good for 60 days and then renewable for an additional 60 days (see below for more details on ways to extend your lock).</li>
        <li>
          If something material about the property or your financial position changes, your rate may change - see the most common reasons below - or you may not qualify for a loan.
          So don’t go buy a new car during this time -{' '}
          <ButtonText to="/blog/what-not-to-do-once-youre-approved-for-a-home-loan/" selected>
            here’s
          </ButtonText>{' '}
          more information on ways to not put your loan in jeopardy.
        </li>
      </ul>
      <Title>Reasons Why Your “Locked” Rate Still May Change:</Title>
      <Paragraph>
        It’s not impossible. We don’t want you to get a nasty surprise later, so we make sure you’re aware of these few ways that a rate you thought was locked may still change.
      </Paragraph>
      <ul>
        <li>Your loan amount changes from your initial estimated loan amount.</li>
        <li>The value of the property changes from your initial estimate.</li>
        <li>You decide you do not want your escrow account (property tax and insurance included in payment) after locking.</li>
        <li>Your credit score changes from the time of your initial application.</li>
        <li>The property type changes from a single family home to a condo or vice versa.</li>
        <li>If you change the term of your loan (i.e. switch between a 30-year and a 15-year).</li>
        <li>
          If you decide to change the occupancy type to a second home or investment property, your lock commitment is no longer valid. If this happens, we will give you an updated
          market rate.
        </li>
        <li>If you decide to buy in a different state (i.e. you were originally looking in Rhode Island but buy in Massachusetts instead). </li>
      </ul>
      <Title>How to Extend Your Lock</Title>
      <Paragraph>
        Home buying can be ultra competitive and it often takes longer than expected to land the right place. So don’t worry if time runs out on your initial lock as we have a few
        ways to extend it.
      </Paragraph>
      <Paragraph>
        Free Lock Extension Incentives:
        <br />
        There are two ways to “earn” complimentary extensions to your Rate Lock:
      </Paragraph>
      <ul>
        <li>
          <u>“Approval 15”</u>: If you complete the credit review and underwriting process and we give you a “Commitment Letter” showing you are conditionally approved within the
          first 30 days of your lock, we will automatically extend your lock by an additional 15 days. This means your assets, income and debt information are all approved by our
          reviewers and we’d only need to do the reviews of the property (appraisal, title, etc.) and get some updates on your income and assets to ensure nothing has changed.
        </li>
        <li>
          <u>“Quick P&S 15”:</u> If you sign a Purchase and Sales Agreement within the first 45 days of your lock commitment, and we’ve also given you your Commitment letter, we
          will extend your lock for 15 days (in addition to the “Approval 15”).
        </li>
      </ul>
      <Paragraph>Other Ways to Extend your Lock Period:</Paragraph>
      <ul>
        <li>
          <u>“Second 60”</u>: Should you get to the end of your lock period without signing a Purchase and Sales Agreement, you may extend your rate lock period for an additional
          60 days in exchange for an increase in your interest rate by .125% (i.e. if your locked rate was 3.0%, your updated locked rate would be 3.125%). NOTE: you can only make
          this 60-day extension one time.
        </li>
        <li>
          <u>“Standard Extensions”</u>: If, after taking advantage of the above extension options, you still need more time you can use the standard{' '}
          <strong>Beeline Loans lock extension policy</strong>. Extensions are calculated using your loan amount and may be purchased at the following rates:
        </li>
      </ul>
      <Table>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>First Extension</th>
            <th>Second Extension</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>7 Days</td>
            <td>20 basis points</td>
            <td>30 basis points</td>
          </tr>
          <tr>
            <td>14 Days</td>
            <td>40 basis points</td>
            <td>50 basis points</td>
          </tr>
        </tbody>
      </Table>
      <Paragraph>Just FYI, we can only ever offer a maximum of 2 ‘Standard Extensions’ on a Locked loan.</Paragraph>
      <Table>
        <thead>
          <tr>
            <th colSpan="2" style={{ width: '14.285%' }}>
              &nbsp;
            </th>
            <TH colSpan="12">Up to ~6 Months</TH>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="2">Beginning Lock</td>
            <TD colSpan="4" style={{ width: '28.568%' }}>
              60 Days
            </TD>
            <td colSpan="8">&nbsp;</td>
          </tr>
          <tr>
            <td colSpan="2">&quot;Approval 15&quot;</td>
            <td colSpan="4">&nbsp;</td>
            <TD colSpan="1">15</TD>
            <td colSpan="7">&nbsp;</td>
          </tr>
          <tr>
            <td colSpan="2">&quot;Quick P&amp;S 15&quot;</td>
            <td colSpan="5" style={{ width: '35.71%' }}>
              &nbsp;
            </td>
            <TD colSpan="1">15</TD>
            <td colSpan="6">&nbsp;</td>
          </tr>
          <tr>
            <td colSpan="2">&quot;Second 60&quot;</td>
            <td colSpan="6">&nbsp;</td>
            <TD colSpan="4" style={{ width: '28.568%' }}>
              60 Days
            </TD>
            <td colSpan="2" style={{ width: '14.285%' }}>
              &nbsp;
            </td>
          </tr>
          <tr>
            <td colSpan="4" style={{ width: '28.568%' }}>
              &quot;Standard 14 Day Extension&quot;
            </td>
            <td colSpan="8" style={{ width: '57.136%' }}>
              &nbsp;
            </td>
            <TD colSpan="1" style={{ width: '7.142%' }}>
              14
            </TD>
            <td colSpan="1" style={{ width: '7.142%' }}>
              &nbsp;
            </td>
          </tr>
          <tr>
            <td colSpan="4">&quot;Standard 14 Day Extension #2&quot;</td>
            <td colSpan="9" style={{ width: '64.278%' }}>
              &nbsp;
            </td>
            <TD colSpan="1" style={{ width: '7.142%' }}>
              14
            </TD>
          </tr>
        </tbody>
      </Table>
      <Title>What happens if rates drop below what you locked? </Title>
      <ul>
        <li>
          It’s not the end of the world. If you want to break a rate lock to take advantage of a new lower rate, you’ll be charged a $500 lock drop fee to get the new lower rate.
          That’s the price of having it both ways.
        </li>
      </ul>
    </Container>
  </NavOnlyTemplate>
);

export default LockBeforeYouBuy;
